<template>
    <rectangle :width="425" :height="125">
      <template #centered>
        <h1 class="tag-name-clamp">{{ tagName }}</h1>
        <h1 class="tag-name-clamp">{{ connectedName }}</h1>
      </template>

      <template #buttons>
        <b-button variant="success" class="btn-sm" @click="$emit('edit')">Edytuj</b-button>
        <b-button variant="danger" class="btn-sm" @click="$emit('remove')">Usuń</b-button>
      </template>
    </rectangle>
</template>

<script>
import Rectangle from "@/components/rectangle.vue";

export default {
  components: {Rectangle},
  props: {
    tagName: {
      type: String,
      required: true
    },

    connectedName: {
      type: String,
      required: true
    }
  }
}
</script>