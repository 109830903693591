<template>
  <b-modal
      v-model="visibleModal"
      title="Dodawanie nowego Atrybutu"
      title-class="font-18"
      hide-footer
      @hide="hideModal"
      @esc="hideModal">
    <div class="form-group">
      <label>Nazwa Atrybutu</label>
      <input v-model="form.attributeName" type="text" class="form-control"/>
    </div>

    <div class="text-right">
      <b-button @click="addNewAttribute" variant="success">Dodaj Atrybut</b-button>
      <b-button class="ml-1" variant="danger" @click="hideModal">{{  $t('message.cancel') }}</b-button>
    </div>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";

export default {

  data() {
    return {
      visibleModal: false,
      parserObject: null,

      form: {
        attributeName: ""
      }
    }
  },

  methods: {
    tagSetup() {
      return this.parserObject.tagSetup
    },

    openModal(parserObject) {
      this.parserObject = parserObject
      this.visibleModal = true
    },

    hideModal() {
      this.visibleModal = false
      this.parserObject = null
      this.form.attributeName = ""
    },


    addNewAttribute() {
      if (this.tagSetup().frontendAttributes.find(element => element.name === this.form.attributeName)) {
        Swal.fire("Błąd!", "Istnieje już Atrybut z taką nazwą!", "error")
        return
      }

      this.tagSetup().frontendAttributes.push({
        name: this.form.attributeName
      })

      this.hideModal()
    }
  }

}
</script>