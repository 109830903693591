<template>
  <rectangle>
    <template #centered>
      <h1 class="tag-name-clamp">(<b>{{ attributeName }}</b>)</h1>
      <h1 v-if="matchedCount() > 0" class="tag-name-clamp">{{ matchedText() }}</h1>
    </template>

    <template #buttons>
      <b-button variant="success" class="btn-sm" @click="$emit('select-attribute', attributeName)">Wybierz</b-button>
    </template>
  </rectangle>
</template>

<script>
import {formatHelper} from "@/helpers/format-helper";
import Rectangle from "@/components/rectangle.vue";

export default {
  components: {Rectangle},

  props: {
    attributeName: {
      type: String,
      required: true
    },

    parserObject: {
      type: Object,
      required: true
    }
  },

  methods: {
    tagSetup() {
      return this.parserObject.tagSetup
    },

    matchedCount() {
      return this.tagSetup().matchedTags.filter(element => this.attributeName in element).length
    },

    matchedText() {
      const count = this.matchedCount()
      return `${count} ${formatHelper.format(count, "dopasowań", "dopasowania", "dopasowań")}`
    }
  }

}
</script>