<template>
  <rectangle>
    <template #centered>
      <h1 class="tag-name-clamp">{{ attributeName }}</h1>
      <h1 v-if="matchedCount() > 0" class="tag-name-clamp">{{ matchedText() }}</h1>
    </template>

    <template #buttons>
      <b-button variant="success" class="btn-sm" @click="$emit('match-attribute', attributeName)">Dopasuj</b-button>
      <b-button v-if="!!matchedTag()" @click="$root.$emit('open-parser-reject-match-modal', parserObject.parser.xmlUrl, tagSetup, matchedLeftTag(matchedTag()), false)" variant="danger" class="btn-sm">Odrzuć dopasowania</b-button>
    </template>
  </rectangle>
</template>

<script>
import {formatHelper} from "@/helpers/format-helper";
import Rectangle from "@/components/rectangle.vue";

export default {
  components: {Rectangle},
  props: {
    attributeName: {
      type: String,
      required: true
    },

    parserObject: {
      type: Object,
      required: true
    },
    
    tagSetup: {
      type: Object,
      required: true
    }
  },

  methods: {
    matchedTag() {
      return this.tagSetup.matchedTags.find(item => item.name === this.attributeName);
    },

    matchedLeftTag(matchedTag) {
      return matchedTag ? this.parserObject.leftTags.find(item => item.path === matchedTag.tagPath) : null;
    },

    matchedCount() {
      return this.tagSetup.matchedTags.filter(element => this.attributeName in element).length
    },

    matchedText() {
      const count = this.matchedCount()
      return `${count} ${formatHelper.format(count, "dopasowań", "dopasowania", "dopasowań")}`
    }
  }

}
</script>