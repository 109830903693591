<template>
  <rectangle :rejected="tagSetup.rejectedTags.includes(tag.path)" :matched="!!matchedTag()" :key="`parser-left-column-${reloadColumn}`">
    <template #top-align-left>
      <b-button variant="warning" class="btn-sm" @click="$emit('highlight-tag', tag.tagName)"><i class="fa fa-highlighter" /></b-button>
    </template>

    <template #centered>
      <h1 class="tag-name-clamp">{{ tag.tagName }}</h1>
      <h1 v-if="!!matchedTag()" class="tag-name-clamp">(<b>{{ tagName() }}</b>)</h1>
      <h1 v-if="attributesLength() > 0" class="tag-name-clamp">Atrybuty: {{  matchedAttributes() }}/{{ attributesLength() }}</h1>
    </template>

    <template #buttons>
      <b-button @click="$root.$emit('open-parser-tag-preview-modal', tag)" variant="primary" class="btn-sm">Podgląd</b-button>

      <template v-if="!tagSetup.rejectedTags.includes(tag.path)">
        <b-button variant="info" class="btn-sm" @click="$emit('match-tag', tag, false)">Dopasuj</b-button>
        <b-button v-if="attributesLength() > 0" variant="info" class="btn-sm" @click="$emit('match-tag', tag, true)">Dopasuj atrybuty</b-button>
      </template>

      <b-button variant="warning" class="btn-sm" @click="$emit('reject-tag', tag)" v-if="!tagSetup.rejectedTags.includes(tag.path)">Odrzuć TAG</b-button>
      <b-button variant="success" class="btn-sm" @click="$emit('restore-tag', tag)" v-else>Przywróć TAG</b-button>

      <b-button v-if="!!matchedTag()" variant="danger" class="btn-sm" @click="$root.$emit('open-parser-reject-match-modal', parserObject.parser.xmlUrl, tagSetup, tag, false)">Odrzuć dopasowania</b-button>
    </template>
  </rectangle>
</template>

<script>
import Rectangle from "@/components/rectangle.vue";
import {translateRightTagName} from "@/helpers/parser-util";

export default {
  components: {Rectangle},

  props: {
    parserObject: {
      type: Object,
      required: true
    },

    tagSetup: {
      type: Object,
      required: true
    },

    tag: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      reloadColumn: false
    }
  },

  methods: {
    // thatMatchingTag() {
    //   return this.matchingTag && this.matchingTag.path === this.tag.path
    // },

    matchedTag() {
      return this.tagSetup.matchedTags.find(element => element.tagPath === this.tag.path);
    },

    tagName() {
      const ecatTag = this.matchedTag()
      return ecatTag ? translateRightTagName(ecatTag.name) : "";
    },

    attributesLength() {
      return Object.keys(this.tag.attributes).length
    },

    matchedAttributes() {
      const ecatTag = this.matchedTag()
      return ecatTag && ecatTag.tagAttributes ? Object.keys(ecatTag.tagAttributes).length : "0";
    }
  },

  mounted() {
    this.$root.$on("reload-parser-columns", () => {
      this.reloadColumn = !this.reloadColumn
    })
  },

  beforeDestroy() {
    this.$root.$off("reload-parser-columns");
  }

}
</script>