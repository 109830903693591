<template>
  <div>
    <parser-select-relation-ship-attribute-modal
        ref="parserSelectRelationShipAttributeModal"
        v-if="parserObject"
        :parser-object="parserObject"
        @clear-who-tag="whoTag = ''"
        @set-attribute="setAttribute"
    />

  <b-modal
      centered
      size="xl"
      v-if="parserObject && tagSetup()"
      v-model="visibleModal"
      title="Dodawanie nowej relacji"
      title-class="font-18"
      hide-footer
      @hide="hideModal"
      @esc="hideModal">
    <div class="form-group">
      <div class="row">
        <div class="col-12 col-lg-6">
          <card>
              <div class="parser-rectangle-table">
                <div class="parser-rectangle-content">
                  <parser-relation-left-column
                      v-for="(tag, index) in parserObject.leftTags"
                      :tag="tag"
                      :parser-object="parserObject"
                      @match-tag="leftTag => matchLeftTagRelation(leftTag, false)"
                      @match-tag-attributes="leftTag => matchLeftTagRelation(leftTag, true)"
                      :key="`parser-relation-left-column-${index}`"
                  />

                </div>
              </div>
          </card>
          </div>

        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label>Nazwa poglądowa:</label>
            <input v-model="form.name" type="text" class="form-control" />
          </div>

          <div class="form-group">
            <label>Tag w produkcie (klucz):</label>
            <div class="row">
              <div class="col-12 col-lg-1 py-1">
                <b-button @click="selectRightTagRelation('keyTag')" v-if="!form.keyTag.path" :disabled="!selectedTag" variant="success" class="btn-sm"><i class="mdi mdi-plus" /></b-button>
                <b-button @click="unselectRightTagRelation('keyTag')" v-else variant="danger" class="btn-sm"><i class="mdi mdi-delete" /></b-button>
              </div>

              <div class="col-12 col-lg-11">
                <input :value="relationTagPath('keyTag')" :disabled="true" type="text" class="form-control" />
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>Tag wartości:</label>
            <div class="row">
              <div class="col-12 col-lg-1 py-1">
                <b-button @click="selectRightTagRelation('valueTag')" v-if="!form.valueTag.path" :disabled="!selectedTag" variant="success" class="btn-sm"><i class="mdi mdi-plus" /></b-button>
                <b-button @click="unselectRightTagRelation('valueTag')" v-else variant="danger" class="btn-sm"><i class="mdi mdi-delete" /></b-button>
              </div>

              <div class="col-12 col-lg-11">
                <input :value="relationTagPath('valueTag')" :disabled="true" type="text" class="form-control" />
              </div>
            </div>
          </div>

          <div class="form-group">
            <b-button @click="addOrUpdateRelation" variant="success">Zatwierdź</b-button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import ParserRelationLeftColumn from "@/components/parser/parser-relation-left-column.vue";
import ParserSelectRelationShipAttributeModal
  from "@/components/parser/parser-select-relation-ship-attribute-modal.vue";

export default {
  components: {ParserSelectRelationShipAttributeModal, ParserRelationLeftColumn},
  data() {
    return {
      visibleModal: false,
      parserObject: null,

      attributes: false,
      selectedTag: null,
      whoTag: "",
      editing: false,

      form: {
        name: "",

        keyTag: {
          path: null,
          targetAttributeName: null
        },

        valueTag: {
          path: null,
          targetAttributeName: null
        }
      }
    }
  },

  methods: {
    tagSetup() {
      return this.parserObject.tagSetup
    },

    openModal(parserObject) {
      this.parserObject = parserObject
      this.visibleModal = true
    },

    openEditingModal(parserObject, relationShip) {
      this.editing = true
      this.form.name = relationShip.name
      this.form.keyTag = Object.assign({}, relationShip.keyTag)
      this.form.valueTag = Object.assign({}, relationShip.valueTag)

      this.openModal(parserObject)
    },

    hideModal() {
      this.visibleModal = false
      this.parserObject = null

      this.editing = false

      this.form.name = ""

      this.form.keyTag.path = ""
      this.form.keyTag.targetAttributeName = ""

      this.form.valueTag.path = ""
      this.form.valueTag.targetAttributeName = ""
    },

    selectRightTagRelation(whoTag) {
      if (whoTag === "valueTag" && !this.attributes) {
        Swal.fire(this.$t('message.error'), "Do tagu wartości można dopasowywać tylko atrybuty!", "error");
        return
      }

      this.whoTag = whoTag
      
      if (!this.attributes) {
        this.$refs.parserSelectRelationShipAttributeModal.selectRelationAttribute(null)
        return
      }

      this.$refs.parserSelectRelationShipAttributeModal.openModal(this.selectedTag.attributes)
    },

    matchLeftTagRelation(tag, attributes) {
      this.selectedTag = tag
      this.attributes = attributes
    },
    
    unselectRightTagRelation(tagName) {
      this.form[tagName].path = null
      this.form[tagName].targetAttributeName = null
    },

    relationTagPath(tagName) {
      if (!this.form[tagName].path) {
        return ""
      }

      return !this.form[tagName].targetAttributeName 
          ? this.form[tagName].path 
          : this.form[tagName].path + "/" + this.form[tagName].targetAttributeName;
    },

    addOrUpdateRelation() {
      if (!this.editing && this.tagSetup().relationships.find(element => element.name === this.form.name)) {
        Swal.fire(this.$t('message.error'), "Istnieje już relacja o takie nazwie!", "error");
        return
      }

      const relationShip = this.tagSetup().relationships.find(element => element.name === this.form.name)
      const {name, keyTag, valueTag } = Object.assign({}, this.form)

      if (relationShip) {
        relationShip.name = name
        relationShip.keyTag = Object.assign({}, keyTag)
        relationShip.valueTag = Object.assign({}, valueTag)
      } else {
        this.tagSetup().relationships.push({
          name: name,
          keyTag: Object.assign({}, keyTag),
          valueTag: Object.assign({}, valueTag)
        })
      }

      this.$emit("can-reload")
      this.hideModal()
    },
    
    setAttribute(attributeName) {
      this.form[this.whoTag].path = this.selectedTag.path
      this.form[this.whoTag].targetAttributeName = attributeName
      this.selectedTag = null
      this.attributes = false
    }
  }
  
}
</script>