<template>
  <div class="row d-flex justify-content-center">
    <div class="col-12 d-inline-flex">
      <div class="py-2 mx-2" v-if="$slots['top-left-buttons']">
        <slot name="top-left-buttons" />
      </div>

      <div class="content-container d-flex align-items-start">
        <div class="rectangle-container">
          <div class="rectangle" :class="rejected ? 'rectangle-rejected-color' : matched ? 'rectangle-matched-color' : required ? 'rectangle-required-color' : ''" :style="cssStyle">
            <div class="py-1 mx-2 align-left" v-if="$slots['top-align-left']">
              <slot name="top-align-left" />
            </div>

            <slot name="top" />

            <div class="centered-data text-center">
              <slot name="centered" />
            </div>
          </div>
        </div>

        <div class="buttons-container">
          <slot name="buttons" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rejected: {
      type: Boolean,
      default: false
    },

    matched: {
      type: Boolean,
      default: false
    },

    required: {
      type: Boolean,
      default: false
    },

    width: {
      type: Number,
      default: 0
    },

    height: {
      type: Number,
      default: 0
    }
  },

  computed: {
    cssStyle() {
      let style = ''
      if (this.width > 0) {
        style += `width: ${this.width}px !important; `
      }

      if (this.height > 0) {
        style += `height: ${this.height}px !important; `
      }

      return style
    }
  }

}
</script>

<style scoped>
.rectangle {
  position: relative;
  width: 250px;
  height: 125px;
  background-color: var(--primary);
  border: 1px solid #0061d0;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 5%;
  flex-grow: 1;
}

.rectangle-rejected-color {
  background-color: #6b6b6b !important;
  border: 1px solid #363636 !important;
}

.rectangle-matched-color {
  background-color: #1cbb8c !important;
  border: 1px solid #17a980 !important;
}

.rectangle-required-color {
  border: 1px solid #b60000 !important;
}

.rectangle-first-matched-color {
  background-color: #e79800 !important;
  border: 1px solid #e79800 !important;
}

.centered-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.float-right button {
  margin-bottom: 10px !important;
  display: block;
}

.align-left {
  position: absolute;
  left: 0;
  top: 0;
}

@media (max-width: 1800px) {
  .rectangle {
    width: 150px;
    height: 150px;
  }
}

.buttons-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.buttons-container button {
  margin-bottom: 5px;
}

.rectangle-container {
  margin-right: 10px;
}
</style>