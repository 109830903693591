<template>
  <rectangle :rejected="tagSetup().rejectedTags.includes(tag.path)" :matched="!!matchedTag()">
    <template #centered>
      <h1 class="tag-name-clamp">{{ tag.tagName }}</h1>
      <h1 v-if="!!matchedTag()" class="tag-name-clamp">(<b>{{ tagName() }}</b>)</h1>
      <h1 v-if="attributesLength() > 0" class="tag-name-clamp">Atrybuty: {{  matchedAttributes() }}/{{ attributesLength() }}</h1>
    </template>

    <template #buttons>
      <b-button variant="success" class="btn-sm" @click="$emit('match-tag', tag)">Dopasuj TAG</b-button>
      <b-button v-if="attributesLength() > 0" variant="success" class="btn-sm" @click="$emit('match-tag-attributes', tag)">Dopasuj atrybuty</b-button>
    </template>
  </rectangle>
</template>

<script>
import Rectangle from "@/components/rectangle.vue";
import {translateRightTagName} from "@/helpers/parser-util";

export default {
  components: {Rectangle},
  props: {
    tag: {
      type: Object,
      required: true
    },

    parserObject: {
      type: Object,
      required: true
    }
  },

  methods: {
    tagSetup() {
      return this.parserObject.tagSetup
    },

    matchedTag() {
      return this.tagSetup().matchedTags.find(element => element.tagPath === this.tag.path);
    },

    tagName() {
      const ecatTag = this.matchedTag()
      return ecatTag ? translateRightTagName(ecatTag.name) : "";
    },

    attributesLength() {
      return Object.keys(this.tag.attributes).length
    },

    matchedAttributes() {
      const ecatTag = this.matchedTag()
      return ecatTag && ecatTag.tagAttributes ? Object.keys(ecatTag.tagAttributes).length : "0";
    }
  }

}
</script>