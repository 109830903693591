<template>
  <b-modal
      v-model="visibleModal"
      title="Dodawanie nowego XML"
      title-class="font-18"
      hide-footer
      @hide="hideModal"
      @esc="hideModal">
    <div class="form-group">
      <label>Link do pliku XML</label>
      <input v-model="xmlUrl" type="text" class="form-control"/>
    </div>

    <div class="text-right">
      <b-button @click="addNewXMLUrl" variant="success">Dodaj</b-button>
      <b-button class="ml-1" variant="danger" @click="hideModal">{{  $t('message.cancel') }}</b-button>
    </div>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";

export default {

  props: {
    warehouseId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      visibleModal: false,
      xmlUrl: ""
    }
  },

  methods: {
    openModal() {
      if (!this.warehouseId) {
        Swal.fire("Błąd!", "Aby rozpocząć tworzenie parsera wybierz hurtownie!", "error");
        return
      }

      this.visibleModal = true
    },

    hideModal() {
      this.visibleModal = false
      this.xmlUrl = ""
    },

    addNewXMLUrl() {
      this.$emit("add", this.xmlUrl)
    }
  }

}
</script>