<template>
  <div ref="codeBlock" style="width: 100%;">
    <pre :class="languageClass"><code :id="`content-${index}`">{{ code }}</code></pre>
  </div>
</template>

<script>
import Prism from 'prism-es6';

export default {
  name: 'ecat-code-highlight',
  props: {
    index: {
      type: Number,
      required: true
    },

    language: {
      type: String,
      default: 'javascript',
    },

    code: {
      type: String
    }
  },

  computed: {
    languageClass() {
      return `language-${this.language}`;
    }
  },

  mounted() {
    Prism.highlightAllUnder(this.$refs.codeBlock);
  },

  beforeUpdate() {
    Prism.highlightAllUnder(this.$refs.codeBlock);
  },

  methods: {
    escapeHTML(text) {
      const element = document.createElement('code');
      element.textContent = text;
      return element.textContent;
    },

    escapeRegExp(text) {
      return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    },

    highlightText(searchText) {
      const content = document.getElementById(`content-${this.index}`);

      searchText = this.escapeRegExp(this.escapeHTML(searchText));

      const searchRegEx = new RegExp('(' + searchText + ')', 'gi');
      const matches = this.code.matchAll(searchRegEx);

      content.querySelectorAll('.parser-highlight-text').forEach(node => {
        node.outerHTML = node.textContent;
      });

      const spans = [];
      for (const match of matches) {
        const span = document.createElement('span');
        span.textContent = match[0];
        span.classList.add('parser-highlight-text');
        spans.push(span);
      }

      spans.forEach(span => {
        content.innerHTML = content.innerHTML.replace(new RegExp(this.escapeRegExp(span.textContent), 'g'), span.outerHTML);
      });
    }
  }

};
</script>