<template>
  <b-modal
      v-if="tagSetup && tag"
      v-model="visibleModal"
      title="Odrzuć dopasowania"
      title-class="font-18"
      hide-footer
      @hide="hideModal"
      @esc="hideModal">
    <div class="text-center form-group" v-for="(matchedTag, index) in tagSetup.matchedTags.filter(element => element.tagPath === tag.path)" :key="index">
      <b>{{ rightTag ? getLastPart(matchedTag.tagPath) : translateRightTagName(matchedTag.name) }}

        <button class="btn btn-sm btn-danger" @click="removeMatchTag(matchedTag)">
          <i class="mdi mdi-delete" />
        </button>
      </b>
    </div>
  </b-modal>
</template>

<script>
import {translateRightTagName} from "@/helpers/parser-util";

export default {

  data() {
    return {
      visibleModal: false,

      xmlUrl: null,
      tagSetup: null,
      tag: null,
      rightTag: false
    }
  },

  methods: {
    translateRightTagName,

    getLastPart(text) {
      return text.includes('.') ? text.split('.').pop() : text;
    },

    openModal(xmlUrl, tagSetup, tag, rightTag) {
      this.xmlUrl = xmlUrl
      this.tagSetup = tagSetup
      this.tag = tag
      this.rightTag = rightTag
      this.visibleModal = true
    },

    hideModal() {
      this.visibleModal = false
      this.xmlUrl = null
      this.tagSetup = null
      this.tag = null
      this.rightTag = false
    },

    removeMatchTag(tag) {
      this.tagSetup.matchedTags = this.tagSetup.matchedTags.filter(element => element !== tag);
      this.$root.$emit("ecat-parser-refresh-table", this.xmlUrl)
      this.$forceUpdate()
      this.$root.$emit("reload-parser-column", this.xmlUrl)
    }
  }

}
</script>